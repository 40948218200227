.label {
  font-size: 20px;
  font-family: 'AvenirNext';
  font-weight: bold;
  width: 160px;
  vertical-align: top;
}
.value {
  font-size: 20px;
  font-family:"AvenirNext";
  margin-bottom: 15px;
}