@import "../../../colors.scss";
@import "../../_GenericElements/_GenericFormElements/form.module.scss";
.root {
    font-size: 12px;
}

.header {
    margin: 0 0 0px;
}

.masterdata {
    padding: 10px;
    div {
        padding: 3px 0;
    }
}

.myinfotext {
  margin-bottom: 3.5em;
}

.pre {
    display: "block";
    padding: "10px 30px";
    margin: "0";
    overflow: "scroll";
}

.updatebtn {
    font-family: "AvenirNext";
    font-size: 24px;
    margin-top: 15px;
}
.profilelabelvalue {
    td:first-child {
        width: 200px;
    }
}

.submitBtn {
    display: block;
    padding: 5px 5px 5px 5px;
    width: 300px;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (max-width: 760px) {
        justify-content: center;
    }
}
@media only screen and (max-width: 760px) {
    .updatebtn {
        text-align: center;
    }
    .submitBtn {
        text-align: center;
    }
}
