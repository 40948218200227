@import "../../../global.scss";

.listdiv {
    margin-bottom: 30px;
    .registerbtndiv {
        text-align: right;
    }

    @media only screen and (max-width: 760px) {
        display: flex;
        flex-direction: column;
    }
}

