
.submitBtn {
    display: block;
    padding: 5px 5px 5px 5px;
    width: 300px;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
}
@media only screen and (max-width: 760px) {
    .submitBtn {text-align: center;}
}