@import "../../../global.scss";

.statusdiv {
    margin: 0 0 25px;
    font-size: 20px;
    font-family: 'AvenirNext';
    font-weight: bold;
    color:$red
}

.guestdiv,.materialdiv,.practicalinfo, .pricediv {
    padding-top: 25px;
}
.registrationdiv {
    padding-top: 20px;
    h3 {
        font-family: "DMBold";
    }
}
.pricediv{
    padding-bottom: 10px;
}
.registrationdiv,
.guestdiv {
    @media screen and (max-width: 420px) {
        padding-top: 5px;
    }
}
.labelvalue{
    border-spacing: 0 1em;
}
.practicalinfo{
    padding-bottom:25px;
    @media screen and (max-width: 420px) {
        padding-bottom: 5px;
    }
}
.root {
    font-size: 12px;
    margin-bottom: 50px;
}
.contactdata {
    padding: 10px;
    div {
        padding: 3px 0;
    }
}
.updatebtn {
    font-family: "AvenirNext";
    font-size: 24px;
    margin-top: 15px;
}

@media only screen and (max-width: 760px) {
    .updatebtn {text-align: center;}
   
    
}
.header {
    margin: 0 0 0px;
}