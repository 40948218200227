@import "../../_GenericElements/_GenericFormElements/form.module.scss";

.eventSignupForm {
    display: block;
    label {
        display: block;
    }
}
.submitBtndiv {
    margin-top: 10px;
    text-align: right;
}
.fullPrice {
    margin-bottom: 15px;
}
