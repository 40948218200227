@import "./colors.scss";

a {
  cursor: pointer;
}

.textarea {
  height: 50px;
}

.errortext {
  color: red;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 15px;
}

ul {
  margin: 0;
  padding: 0;
}
.elementcontent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  max-width: 840px;
}

