
.submitBtn {
    display: block;
    padding: 5px 5px 5px 5px;
    width: 300px;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
}

.errorMsg {
    color: red;    
    font-family: "DMBold";
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-right: 14px;
    margin-bottom: 14px;
    margin-left: 14px;
}

.dmForm {
    flex-direction: column; 
    display: flex;
    align-items: flex-start;
    max-width: 600px;
    min-width: 400px;
    align-self: left;
}
@media only screen and (max-width: 760px) {
    .dmForm {min-width: 0;}
    .submitBtn {text-align: center;}
}
.yesNoControl {
    label {
        padding-bottom: 0.5em;
    }
    
    input[type="radio"] {
        max-width: 3em;
        display: inline;
     }

     span {
         margin-right: 2em;
     }
}