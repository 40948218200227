.checkboxdiv {
    display: flex;
    padding-bottom: 10px;
    div {
        padding-left: 10px;
        padding-top:8px;
    }
    label {
        text-align: left;
    }
    span {
        align-items: start;
        height: fit-content;
    }
}
