@import "../../../global.scss";

.dmtablecell {
   font-family: "AvenirNext";
}

.dmtablecellBold {
   font-weight: bold;
   font-family: "AvenirNext";
}

table.root {
   border-spacing: 0 8px;
   border: 0;
}

table.root {
   th {
      border-bottom: 0;
      cursor: default;
   }

   td {
      background-color: var(--dm_sand);
      font-size: 20px;
      border-bottom: 0;
      cursor: default;
   }
}

.fileitem {
   cursor: default;
   display: flex;

   button {
      height: 50%;
      align-self: center;
   }
   svg {
      width: 2em;
   }

   span {
      display: inline-block;
      max-width: 80%;
      white-space: nowrap;
      font-size: x-small;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 1.2em;
   }
}

table.root tbody tr.clickable:hover td {
   border-bottom: 4px solid var(--dm_middle_red);
   /* To compensate for the 16px set on MUI cells*/
   padding-bottom: 2px;
   cursor: pointer;
}

table.root tbody tr{
   height:67px;
}

tr.collapsible {
   td:first-child{
      width: 15px;
   }
   td {
      vertical-align: top;
      padding-top: 1.5em;
   }

   td > .collapsed {
      -moz-transition: all 1s ease-in-out;
      -ms-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      -webkit-transition: all 1s ease-in-out;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
   }

   td > .collapsed > div {
      text-overflow: ellipsis;
      overflow: hidden;
      overflow-wrap: anywhere;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-inline-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
   }

   .hiding {
      display: unset;
   }

   .showing {
      display: none;
   }
}

tr.collapsible.show {
   td > .collapsed {
   }

   td > .collapsed > div {
      display: inline-block;
      overflow: unset;
   }

   .hiding {
      display: none;
   }

   .showing {
      display: unset;
   }
}

.datetimecell {
   .date {
      white-space: nowrap;
      font-size: smaller;
   }
   .time {
      white-space: nowrap;
      font-size: smaller;
   }

   svg {
      color: var(--dm_coral);
   }
}
