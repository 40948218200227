@import "../../../global.scss";
@import "../../_GenericElements/_GenericFormElements/form.module.scss";


.cancelbtn{
    background-color: $redbrown;
    margin-top:3px;
}
.cancelbtndiv{
    margin-right:10px
}
.btnsdiv{
    display: inline-flex;
}
.listdiv{
    margin-bottom:30px;
}
.labeltr{
    td:first-child{
       width:240px
    }
}
.successMsg{
    color:$green;
}