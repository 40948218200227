.dm_subnav_item.dm_nav_item--current a {
  text-decoration-line: underline;
  text-decoration-color: var(--themefontcolor);
}
.logoutButton {
  &:hover {
    background-color: #00c382 !important;
  }
}

.topButton {
  padding: "6px 16px";
  font-weight: 600;
  color: "#000000";
  font-family: "AvenirNext";
  font-size: "14px";
  border-radius: 0;
  background-color: "#FFC8CF";
  width: 104px !important;
  height: 80px;

  &:hover {
    border-radius: "0";
  }

  @media only screen and (max-width: 760px) {
    width: 50% !important;
  }
}

.mobileButton {
  padding: 0;
  border: 0;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  outline: 0;
  font-size: 30px;
  margin-right: 20px;
}
.mobileMenu {
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.dropdownMenuItem:hover {
  margin-bottom: -3px;
  border-bottom: 3px solid;
}
