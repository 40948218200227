.filesdiv {
        margin:10px 0;
}

.helperdiv {
        margin-left:20px;
}
     
.deletefileicon{
        cursor: pointer;
}