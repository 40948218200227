.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid var(--themecolor); /* Blue */
    border-radius: 50%;
    animation: spin 2s linear infinite;
    width: 25px;
    height: 25px;
    margin: auto;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
