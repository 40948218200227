@import "../../../colors.scss";

.richtext {   
   background-color: transparent;
   * {
      font-family:"AvenirNext" !important;
      font-size:20px !important;
      background-color: transparent !important ;
      
   }
}
.richtextdiv {
   margin: 15px 5px;
   padding-left: 3%;
   h2 {
      margin-bottom: 0;
   }
   p {
      &:first-child {
         margin-top: 5px;
      }
   }
   a {
      text-decoration: underline;
      &:hover {
         color: $red;
      }
   }
   button {
      margin-top: 15px;
   }
   
}
@media only screen and (max-width: 760px) {
   .richtextdiv {text-align: center;}
}
