.consentform {
  margin-bottom: 60px;
}

.consentrow {
  display: flex;
  flex-direction: row;
}

.consentrowmobile {
  flex-direction: row;
}


.consentrowcheckbox {
  display: flex;
  flex-direction: row;
  &.isMobile {
    flex-direction: column;
  }
  &.expanded {
    max-height: none;
    overflow: visible;
    span {
    -webkit-line-clamp: unset;
    }
}
}

.consentcolumn {
  display: flex;
	flex-direction: column;
}

.consentcheckbox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
}

.submit {
  display: flex;
}

.mobilesubmit {
  align-items: center;
  justify-content: center;
}

.consentlist {
  margin-top: 20px
}

.title {
  margin-bottom: 15px;
}

.description {
  flex-direction: column;
  span {
    white-space: pre-wrap;
    min-width: 600px;
    margin-right: 6px;
    max-width: 600px;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 6px;
  }
  h4 {
    text-align: start;
    margin: 0;
    cursor: pointer;
    margin-bottom: 15px;
    display: flex;
    width: fit-content;
  }
}

.mobiledescription {
  flex-direction: column;
  span {
    white-space: pre-wrap;
    max-width: 600px;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 6px;
  }
  h4 {
    text-align: start;
    margin: 0;
    cursor: pointer;
    margin-bottom: 15px;
    display: flex;
    width: fit-content;
  }
}
