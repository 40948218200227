//DM colors from the designguide
$pink: #FFD7DC;
$blue: #2D7DFF;
$darkblue: #141E4B;
$yellow: #FFF064;
$red: #FF4B4B;
$green: #00D28C;
$beige: #F5F0EB;
$redbrown: #5A0014;
$sand: #ebe6e1;
$form_bgcolor: var(--form_bgcolor);
$form_disabled_bgcolor: var(--form_disabled_bgcolor);
$form_box_color: var(--form_box_color);
$form_bgcolor__hover: var(--form_bgcolor__hover);